<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap mb-3">
        <c-input v-model="search.id" label="ID" search-form />
        <c-input v-model="search.firstName" :label="$t('shared.firstName')" search-form />
        <c-input v-model="search.lastName" :label="$t('shared.lastName')" search-form />
        <mobile-input v-model="search.mobile" :label="$t('shared.mobile')" search-form />
      </div>

      <div class="ma-1">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <div class="d-flex mb-4">
      <v-spacer />
      <v-btn color="success" dark to="create" small>
        <v-icon small> mdi-plus </v-icon>
        {{ $t('shared.add') }}
      </v-btn>
    </div>

    <v-data-table
      :items="items"
      :headers="headers"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-tooltip v-if="$hasPermission('org.identity.roles')" top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="warning" :to="`${item.user.id}/roles`" v-on="on">
                <v-icon> mdi-account-key </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.permissions') }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="info" :to="`edit/${item.user.id}`" v-on="on">
                <v-icon> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.view') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.image`]="{item}">
        <Thumbnail v-if="item.profile.image" :src="item.profile.image" width="40" height="40" />
      </template>

      <template #[`item.mobile`]="{item}">
        {{ item.user.mobile }}
      </template>

      <template #[`item.id`]="{item}">
        {{ item.user.id }}
      </template>

      <template #[`item.firstName`]="{item}">
        {{ item.user.firstName }}
      </template>

      <template #[`item.lastName`]="{item}">
        {{ item.user.lastName }}
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      headers: [
        {text: 'ID', value: 'id'},
        {text: this.$t('shared.image'), value: 'image'},
        {text: this.$t('shared.firstName'), value: 'firstName', sortable: true},
        {text: this.$t('shared.lastName'), value: 'lastName'},
        {text: this.$t('shared.mobile'), value: 'mobile'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      items: [],
      search: {
        id: null,
        workingSiteId: null,
        firstName: null,
        lastName: null,
        mobile: null,
        email: null,
        verified: null
      }
    }
  },
  methods: {
    filter(isCount) {
      const filter = this.$filter()

      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    getItems() {
      return this.$api.org.users.getAll(this.filter()).then((res) => {
        this.items = res
      })
    },
    getCount() {
      return this.$api.org.users.count(this.filter(true)).then((res) => {
        this.count = res
      })
    },
    async filtering() {
      try {
        this.progressing = true
        await Promise.all([this.getItems(), this.getCount()])
        this.progressing = false
      } catch (error) {
        this.$showError(error)
      }
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.org.users.delete(item.user.id)
        this.filtering()
        this.$showSuccess(this.$t('users.userRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
